:root {
  --linea-par: white;
  --linea-impar: #f6f6f6d0;
  --primary-button: rgb(1, 135, 212);
  --primary-button-hover: rgb(1, 162, 255);
  --danger-button: rgb(212, 1, 1);
  --danger-button-hover: rgb(255, 0, 0);

  --background-green: #45b8ac;
  --background-green-hover: #45b8adc0;
  --background-dark: #333;
  --background-dark-hover: #444;
  --title-text-white: #fff;
  --text-dark: #333;
  --background-danger: #dc3545;
  --background-warn: #dcc335;
  --background-grey: #f6f8fb;
}

html {
  font-size: 14px;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*::-webkit-scrollbar {
    width: 6px;
    padding-right: 5px;
}
*::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 4px;
}

body {
  margin: 0;
  color: var(--text-dark);
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-grey);
}

.loading-background {
  animation-name: cargaSuave;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.2);
  transition: left 0.3s, opacity 0.3s;
  -webkit-transition: left 0.3s, opacity 0.3s;
  -moz-transition: left 0.3s, opacity 0.3s;
}

.shadow-box-loading {
  animation-name: cargaSuave;
  box-shadow: 0 1px 5px #888;
}

.loading-box {
  animation-name: cargaSuave;
  position: fixed;
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  z-index: 999;
}

.lds-ring {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.lds-ring div {
  display: block;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: 8px;
  border: 8px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(32, 32, 32) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content-main {
  transition: 0.3s;
}

.main-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--background-grey);
  z-index: 2;
}

.main {
  position: relative;
  z-index: 3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-center {
  text-align: center !important;
  justify-content: center !important;
}

.text-left {
  text-align: left !important;
  justify-content: flex-start !important;
}

.grid-cell-center {
  place-content: center;
}

.text-bold {
  font-weight: bold;
}

.padding-std {
  padding: 0.55rem 0.75rem;
}

.valign-middle,
.grid-label,
.grid-value,
.grid-head,
.datosArticulo,
.labelDireccion,
.campoDatos {
  display: flex;
  align-items: flex-start;
 
  
}

.btn-align-right {
  display: flex;
  justify-content: flex-end;
}

.btn {
  border: none;
  border-radius: 4px;
  padding: 0 0.75rem;
  vertical-align: initial;
}

.btn:hover,
.btn:focus {
  text-decoration: none;
  cursor: pointer;
}

.btn:active {
  transition: 0s;
  transform: scale(0.95);
}

.btn:disabled {
  opacity: 0.5;
  background-color: var(--background-dark);
  cursor: default;
}
.btn:disabled:hover {
  background-color: var(--background-dark);
  cursor: default;
}

.btn-primary {
  color: white;
  background-color: var(--primary-button);
}

.btn-primary:hover {
  background-color: var(--primary-button-hover);
}

.btn-albaran {
  color: white;
  background-color: var(--background-green);
  margin-right: 10px; 
}

.btn-albaran:hover {
  background-color: var(--background-green-hover);
}

.btn-cerrar,
.btn-standard {
  padding: 0.55rem 0.75rem;
}

.btn-danger {
  color: white;
  background-color: var(--danger-button);
}

.btn-danger:hover {
  background-color: var(--danger-button-hover);
}

.btn-crear-pedido {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.btn-pd-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn-mg-v-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #ffffff;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 5px;
}

.modal-content-pdf {
  margin-top: 2rem;
  padding: 10px;
  width: 70%;
  height: 90%;
}

.modal-header {
  display: flex;
}
.modal-title {
  text-decoration: underline;
}

.cruz-cerrar-modal {
  margin-left: auto;
}

.modal-footer {
  text-align: right;
}

.grid-botones-aceptar-cancelar {
  display: grid;
  grid-template-columns: repeat(2, 6rem);
  gap: 1rem;
  justify-content: end;
  margin-top: 1rem;
}
.cerrar:hover,
.cerrar:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.grid-botones-cancelar {
  display: grid;
  grid-template-columns: repeat(1, 6rem);
  gap: 1rem;
  justify-content: end;
  margin-top: 1rem;
}

.cabecera {
  padding: 10px 10px;
}

.cabecera .shadow-box {
  margin-bottom: 0;
}

section {
  background-color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 1px 10px;
}

.section-minimal {
  width: 260px;
  margin: auto;
}

.side-nav {
  border: none;
  margin-bottom: 1rem;
  height: 100%;
  width: 195px;
  transition: 0.3s;
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  background-color: white;
  overflow-x: hidden;
}

.side-nav-show {
  margin-left: 200px;
  transition: 0.3s;
}

.side-nav ul {
  list-style: none;
  text-decoration: underline;
}

.side-nav a {
  padding: 8px 8px 8px 16px;
  font-weight: bold;
  font-size: 1.2rem;
  display: block;
  transition: 0.3s;
  text-decoration: none;
  color: inherit;
}

.side-nav a:visited,
.side-nav a:focus {
  color: inherit;
}

.box-menu {
  display: none;
  margin-right: 2px;
  padding: 3px;
  background-color: var(--background-grey);
  border: solid 2px #333;
  border-radius: 3px;
}

.hide {
  display: none;
}

.hamburger-menu {
  align-content: flex-start;
  vertical-align: middle;
}

.logoEinsa {
  display: flex;
  flex-wrap: wrap;
}

.logoEinsa img {
  max-width: 160px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-left {
  display: flex;
  flex-wrap: wrap;
}

.titulo {
  position: relative;
  text-align: center;
  flex: 1;
}

.cuerpo {
  padding: 10px 10px;
  overflow-y: hidden;
}

input {
  vertical-align: auto;
  width: 100%;
}

.grid-login {
  display: grid;
  gap: 10px;
  padding: 10px;
  grid-template-columns: 1fr 11fr;
}

.login {
  display: flex;
  flex-direction: column;
}
.grid-container-modal {
  display: grid;
  gap: 10px 10px;
  padding: 10px;
  margin: 10px;
}
.pdf-container .react-pdf__toolbar {
  display: none;
}

.grid-label {
  font-weight: bold;
  text-decoration: underline;
}





.grid-head {
  color: #aaa;
  border-bottom: solid 1px #eee;
  margin-bottom: 5px;
   margin-right: '10px' 
}



.shadow-box {
  box-shadow: 0 1px 5px #ddd;
}

.grid-row {
  grid-row: 1;
}

.grid-col {
  grid-column: 1;
}

.grupoDatos {
  display: flex;
  flex-wrap: wrap;
}

.grupoDatos > div {
  min-width: 18%;
}

.contenedorLineas {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px 10px;
  padding: 10px;
  margin: 10px;
}

.articulos-pedido {
  padding-bottom: 10px;
}

.articuloPedido {
  padding-top: 1px;
  padding-bottom: 1px;
}

.seccionLineas {
  padding-top: 10px;
  padding-bottom: 10px;
}

.section-lista-pedidos {
  margin-bottom: 0;
  height: calc(100dvh - 181px);
  overflow-y: hidden;
}

.section-lista-articulos {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: hidden;
  height: calc(100dvh - 109px);
  margin-bottom: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 3fr) 1fr 1fr 1fr 1fr 1fr minmax(
      auto,
      1fr
    );
  gap: 10px 0px;
  margin-bottom: 0;
}

.grid-lista-articulos {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 3fr) 1fr repeat(4, 0.4fr);
  gap: 10px 0px;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 100%;
  align-content: flex-start;
}

.grid-lista-lotes {
  padding: 5px 10px !important;
  grid-column: span 7;
  display: grid;
  text-align: center;
  grid-template-columns: repeat(5, 1fr);
  gap: 0px 0px;
  justify-content: center;
}

.grid-lista-lotes .lineaPar,
.grid-lista-lotes .lineaImpar {
  padding: 5px 0px !important;
}
.articulo-inventario {
  border-bottom: 2px solid #85a7f573;
  background-color: rgb(226, 225, 225);
  font-weight: 600;
}

.section-lista-articulos > .grid-lista-articulos {
  gap: 0;
}

.grid-container > div {
  padding-left: 10px;
  padding-right: 10px;
}

.grid-container > .grid-head,
.grid-lista-articulos > .grid-head {
  text-align: left;
  align-items: flex-end;
  top: 0;
  position: sticky;
  background-color: #fff;
}

.lineaImpar {
  background-color: var(--linea-par);
}

.lineaPar {
  background-color: var(--linea-impar);
}

.lineaPar {
  background-color: #f0f0f0; /* Color de fondo para líneas pares */
}

.lineaImpar {
  background-color: #ffffff; /* Color de fondo para líneas impares */
}

.grid-fechas-entrega {
  display: grid;
  gap: 10px;
  padding: 10px;
  grid-template-columns:
    minmax(5%, 12rem) minmax(10%, 10rem) minmax(5%, 12rem) minmax(10%, 10rem)
    minmax(10%, 1fr);
}

.grid-pedidos {
  display: grid;
  margin: 10px;
  grid-template-columns:
    minmax(8%, 1fr) minmax(7%, 1fr) minmax(8%, 1fr) minmax(7%, 1fr) minmax(
      8%,
      1fr
    )
    minmax(7%, auto) minmax(8%, 2fr) minmax(auto, 2fr) minmax(auto, 2fr) minmax(
      10%,
      2fr
    )
    minmax(10%, auto) minmax(auto, 1fr);
  overflow-y: auto;
  max-height: calc(100% - 20px);
}

.grid-lineasPedido {
  display: grid;
  margin: 5px;
  grid-template-columns:
  auto auto auto;
    
    
  
  overflow-y: auto;
  max-height: calc(100% - 30px);
}


.grid-lineasPedidoD {
  display: grid;
 
  grid-template-columns: repeat(6, auto);
}

.grid-lineasPedidoD  div {
  width: 100%;
  justify-content: center;
}
.grid-lineasPedido div,
.grid-lineasPedido span {
  width: 100%;
  padding: 2px;
  justify-content: center;
}

.grid-pedidos > .grid-value {
  align-items: center;
  justify-content: center;
}


.grid-pedidos > .grid-head {
  align-items: flex-end;
  justify-content: center;
  position: sticky;
  top: 0;
  background-color: #fff;
}
.grid-pedidosDireccion > .grid-head {
  align-items: flex-end;
  justify-content: center;
  position: sticky;
  top: 0;
  background-color: #fff;
}

.grid-pedidos .grid-label,
.grid-pedidos .grid-value,
.grid-head {
  text-align: center;
 
}
.container {
   display: flex;
  margin-right: 100px; 
 
  margin-top: 10px;
 
}
.container1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, min-content);

}

.grid-valuelistapedidos{
  margin: 5px;
  text-align: center;
  
  
 
}

.sortable:hover {
  cursor: pointer;
}

.grid-pedidos > div,
.section-lista-articulos > .grid-lista-articulos > div {
  padding: 5px;
}

.grid-lotes-articulo-pedido {
  display: grid;
  grid-template-columns: max-content auto auto 8rem;
  justify-items: center;
  gap: 8px;
}

.grid-lotes-articulo-pedido input {
  text-align: center;
}
.grid-cliente {
  display: grid;
  gap: 10px 10px;
  padding: 10px;
  margin: 10px;
}

.grid-cliente > .grid-label {
  align-items: flex-end;
}

.CodigoCliente {
  grid-column: 1;
}
.TipoIdentificacion {
  grid-column: 2;
}
.Identificacion {
  grid-column: 3;
}
.Nombre {
  grid-column: 4;
}
.NombreFiscal {
  grid-column: 5;
}
.Contacto {
  grid-column: 6;
}
.Direccion {
  grid-column: 7;
}
.IVA {
  grid-column: 8;
}

section > h3 {
  text-decoration: underline;
}

.grid-direccionEntrega {
  display: grid;
  gap: 10px 10px;
  padding: 10px;
  margin: 10px;
  grid-template-columns: 10% minmax(30%, 20%) 10% 20% auto;
}

.grid-direccionEntrega .label {
  grid-column: 3;
}
.grid-direccionEntrega .selector {
  grid-column: 4;
}
.grid-direccionEntrega .labelDireccion {
  grid-column: 1;
}
.grid-direccionEntrega .campoDatos {
  grid-column: 2;
}

.grid-direccionEntrega .pais {
  grid-row: 1;
}
.grid-direccionEntrega .provincia {
  grid-row: 2;
}

.grid-pedidoWebAlmacen {
  display: grid;
  grid-template-columns:
    0.5fr 0.5fr minmax(8%, 1fr) minmax(8%, 1fr) minmax(8%, 0.5fr)
    minmax(8%, 1fr) minmax(8%, 0.7fr) minmax(8%, 1fr);
  gap: 10px 10px;
  margin: 10px;
}

.grid-pedidoWebAlmacen > .label {
  display: flex;
  align-items: center;
}

.grid-articulos {
  display: grid;
  gap: 10px 10px;
  padding: 10px;
  margin: 10px;
  grid-template-columns: 5% 10% 7% minmax(auto, 50%) 10rem;
}

.filtro-articulo {
  max-width: 100%;
}
.select-articulo {
  min-width: 10%;
  max-width: 100%;
}

.select-lote {
  height: 1.75rem;
}
.error {
  color: red;
  font-size: 1.2rem;
  font-weight: bold;
}

.cerrar-menu {
  display: none;
}

.empresa-in {
  animation-name: divEmpresa;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}
.empresa-out {
  animation-name: divEmpresaOut;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

section,
.cuerpo {
  animation-name: cargaSuave;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes cargaSuave {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes divEmpresa {
  from {
    display: none;
    opacity: 0;
  }
  to {
    display: inherit;
    opacity: 1;
  }
}

@keyframes divEmpresaOut {
  from {
    display: inherit;
    opacity: 1;
  }
  to {
    display: none;
    opacity: 0;
  }
}

@media screen and (max-width: 1300px) {
  .grid-pedidoWebAlmacen {
    grid-template-columns:
      0.5fr 0.5fr minmax(8%, 1fr) minmax(8%, 1fr) minmax(8%, 0.5fr)
      minmax(8%, 1fr);
  }

  .box-menu {
    display: block;
  }

  .hide {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .loading-background {
    left: 0;
  }

  .cerrar-menu {
    display: block;
    text-align: right;
    margin: 10px;
    font-weight: bold;
    font-size: 20px;
  }


  .grid-lista-articulos {
    font-size: 0.85rem;
    grid-template-columns: 1fr minmax(auto, 3fr) 1fr 1fr 1fr 1fr 1.5fr minmax(10%, 1fr);
    gap: 10px 0px;
  }

  .grid-pedidoWebAlmacen {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr;
    gap: 10px 10px;
    margin: 10px;
  }

  .grid-pedidos {
    font-size: 0.8rem;
    grid-template-columns:
      minmax(10%, 1fr) minmax(7%, 1fr) minmax(8%, 1fr) minmax(5%, 1fr) minmax(
        7%,
        1fr
      )
      minmax(7%, 1fr) minmax(7%, 2fr) minmax(auto, 2fr) minmax(auto, 2fr) minmax(
        10%,
        2fr
      )
      minmax(10%, 1fr) minmax(auto, 1fr);
  }

  .grid-fechas-entrega {
    grid-template-columns:
      minmax(5%, 12rem) minmax(10%, 10rem) minmax(5%, 12rem)
      minmax(10%, 10rem);
  }

  .contenedorLineas {
    font-size: 0.7rem;
  }

  .inputArticuloVenta {
    font-size: 0.7rem;
  }

  .btn-danger {
    font-size: 0.7rem;
  }
}
